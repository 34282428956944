var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutBox',{staticStyle:{"cursor":"pointer","transform":"rotateY(6deg) scaleX(1.3) translateY(20px)","transform-origin":"left center"},attrs:{"loading":_vm.loading,"title":'交易复检预警一览('+_vm.total+')'},on:{"onTitleClick":_vm.titleClick}},[_c('ScrollTable',{key:_vm.refreshKey,ref:"ScrollTable",attrs:{"columns":_vm.getColumns,"query":{
      status: '02',
      handleFlagList: '01',
      isTimeDesc: '02',
    },"url":("/" + _vm.$businessISG + "/monitor/deal/deal-page"),"is-global-query":""},on:{"getTotal":function (e){ return _vm.total = e; },"clickRow":function (ref) {
        var row = ref.row;

        return _vm.$refs.EarlyWarningHandleInfoDialog.openDialog({ row: row,type:'transactionRecheck' });
}},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('EarlyWarningHandleInfoDialog',{ref:"EarlyWarningHandleInfoDialog",on:{"success":function($event){_vm.$refs.ScrollTable && _vm.$refs.ScrollTable.refresh()}}}),_c('TransactionTableDialog',{ref:"TransactionTableDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }