<template>
  <LayoutBox
    :loading='loading'
    style='
      cursor: pointer;
      transform: rotateY(6deg) scaleX(1.3) translateY(20px);
      transform-origin: left center;
    '
    :title="'交易复检预警一览('+total+')'"
    @onTitleClick='titleClick'
  >
    <ScrollTable
      :key='refreshKey'
      ref='ScrollTable'
      v-model='loading'
      :columns='getColumns'
      @getTotal="e=> total = e"
      :query="{
        status: '02',
        handleFlagList: '01',
        isTimeDesc: '02',
      }"
      :url='`/${$businessISG}/monitor/deal/deal-page`'
      is-global-query
      @clickRow="
        ({ row }) => $refs.EarlyWarningHandleInfoDialog.openDialog({ row,type:'transactionRecheck' })
      "
    ></ScrollTable>
    <EarlyWarningHandleInfoDialog
      ref='EarlyWarningHandleInfoDialog'
      @success='$refs.ScrollTable && $refs.ScrollTable.refresh()'
    ></EarlyWarningHandleInfoDialog>
    <TransactionTableDialog ref="TransactionTableDialog"></TransactionTableDialog>
  </LayoutBox>
</template>

<script>
import {mapState} from 'vuex';
import EarlyWarningHandleInfoDialog from './EarlyWarningHandleInfoDialog.vue';
import TransactionTableDialog from "./AllTranscheck";

export default {
  //组件注册
  components: {
    LayoutBox: () => import('../components/LayoutBox.vue'),
    ScrollTable: () => import('@/views/screen/components/ScrollTable'),
    EarlyWarningHandleInfoDialog,
    TransactionTableDialog,
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      total: 0
    };
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
    }),
    getColumns() {
      return [
        {
          title: '机构',
          field: 'organizationText',
          width: '20%',
          visible: !this.isSubbranch,
        },
        {
          title: '时间',
          field: 'warningTime',
          width: '40%',
          visible: true,
        },
        {
          title: '监督类型',
          field: 'analysisItemText',
          width: '20%',
          visible: true,
        },
        {
          title: '预警内容',
          field: 'description',
          width: '20%',
          visible: true,
        },
        {
          title: '审核状态',
          field: 'reviewStatusText',
          width: '20%',
          visible: this.isSubbranch,
        },
      ].filter((e) => e.visible);
    },
  },
  //属性监听
  watch: {},
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    titleClick() {
      this.$refs.TransactionTableDialog.openDialog({
        query: {
          handlerFlag:'01',
          status: '02',
          reviewStatus: '01'
        }
      })
    }
  },
};
</script>

<style lang='scss' scoped></style>
